import { app } from "js/namespaces";
import React, { Component } from "react";
import { Tooltip } from "@material-ui/core";

import {
    Section,
    UIPane,
    UIPaneContents,
    UIPaneHeader
} from "js/react/components/UiComponents";
import { ColGrid } from "js/react/components/LayoutGrid";
import { Gap10 } from "js/react/components/Gap";
import { ShowDialog, ShowUpgradeDialog } from "js/react/components/Dialogs/BaseDialog";
import ConfirmationDialog from "js/react/components/Dialogs/ConfirmationDialog";
import Icon from "js/react/components/Icon";
import { PresentationPrivacyType } from "js/core/models/presentation";
import ProBadge from "js/react/components/ProBadge";
import { FeatureType } from "js/core/models/features";
import shouldShowProBadge from "js/core/utilities/shouldShowProBadge";
import { trackActivity } from "js/core/utilities/utilities";
import { UpgradePlanDialogType } from "js/react/views/MarketingDialogs/UpgradePlanDialog";
import { WarningWatermark } from "js/react/components/WarningWatermark";

import CreateLinkDialog from "../dialogs/CreateLinkDialog";
import CreateInviteDialog from "../dialogs/CreateInviteDialog";
import CreateEmbedCodeDialog from "../dialogs/CreateEmbedCodeDialog";
import SocialShareDialog from "../dialogs/SocialShareDialog";
import { LinksList } from "./LinksList";

export default class SharePane extends Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();

        this.state = {
            copied: false,
            linksUpdated: false
        };
    }

    handlePrivacyChange = value => {
        const { presentation } = this.props;

        if (
            !app.user.features.isFeatureEnabled(FeatureType.SECURED_SHARING, presentation.getWorkspaceId()) &&
            value == PresentationPrivacyType.SECURED
        ) {
            ShowUpgradeDialog({
                type: UpgradePlanDialogType.UPGRADE_PLAN,
                workspaceId: presentation.getWorkspaceId(),
                analytics: { cta: "SecuredSharing", ...presentation.getAnalytics() }
            });
            return;
        }

        const analyticsProps = {
            presentation_id: presentation.get("id"),
            old_value: presentation.getPrivacySetting(),
            new_value: value
        };
        trackActivity("Sharing", "ChangePrivacySetting", null, null, analyticsProps, { audit: true });

        if (value == PresentationPrivacyType.PRIVATE) {
            this.setState({
                showPrivacyChangeDialog: true,
                privacyChangeDialogTitle: "Make Presentation Private?",
                privacyChangeDialogMessage:
                    "Anyone you have shared a link with or invited to view this presentation will no longer have access. This presentation will no longer be playable anywhere it is embedded or on any social media.",
                privacyChangeDialogSetting: PresentationPrivacyType.PRIVATE
            });
        } else if (
            value == PresentationPrivacyType.SECURED &&
            presentation.getPrivacySetting() == PresentationPrivacyType.PUBLIC
        ) {
            this.setState({
                showPrivacyChangeDialog: true,
                privacyChangeDialogTitle: "Secure this Presentation?",
                privacyChangeDialogMessage:
                    "Any public links, embeds or social shares for this presentation will be deactivated. Only viewers who have been sent a password protected link or secure email will have access.",
                privacyChangeDialogSetting: PresentationPrivacyType.SECURED
            });
        } else {
            this.applyPrivacyChange(value);
        }
    };

    handlePrivacyChangeDialogOk = () => {
        this.setState({ showPrivacyChangeDialog: false });
        this.applyPrivacyChange(this.state.privacyChangeDialogSetting);
    };

    handlePrivacyChangeDialogCancel = () => {
        this.setState({ showPrivacyChangeDialog: false });
    };

    async applyPrivacyChange(value) {
        const { presentation } = this.props;
        await presentation.setPrivacySetting(value);
        this.forceUpdate();
    }

    handleShareCTA = (cta, dialogClass, dialogProps = {}) => {
        const { presentation } = this.props;
        const analyticsProps = {
            presentation_id: presentation.get("id"),
            cta
        };
        trackActivity("Sharing", "ShareCTAClick", null, null, analyticsProps);
        return ShowDialog(dialogClass, { presentation, onClose: this.handleUpdateLinks, callback: () => this.forceUpdate(), ...dialogProps });
    };

    handleUpdateLinks = () => {
        this.setState({ linksUpdated: true });
    };

    handleResetLinksUpdated = () => {
        this.setState({ linksUpdated: false });
    };

    render() {
        const { presentation } = this.props;
        const privacy = presentation.getPrivacySetting();

        return (
            <UIPane className="share-pane">
                <UIPaneHeader>Share Presentation</UIPaneHeader>
                <UIPaneContents>
                    <Section title="Privacy Settings" description="Set who can view this presentation with a shareable link.">
                        <ColGrid gap={10}>
                            <Tooltip title="Only me and any collaborators can view this presentation.">
                                <div
                                    className={
                                        `privacy-button ${privacy === PresentationPrivacyType.PRIVATE ? "selected" : ""}`
                                    }
                                    onClick={() =>
                                        this.handlePrivacyChange(PresentationPrivacyType.PRIVATE)
                                    }
                                >
                                    <Icon iconName="block" />
                                    <label>Private</label>
                                </div>
                            </Tooltip>
                            <Tooltip title="Require email authentication to view or create links with password protection.">
                                <div
                                    className={
                                        `privacy-button ${privacy === PresentationPrivacyType.SECURED ? "selected" : ""}`
                                    }
                                    onClick={() =>
                                        this.handlePrivacyChange(PresentationPrivacyType.SECURED)
                                    }
                                >
                                    <Icon iconName="lock" />
                                    <label>Secured Viewing</label>
                                    <ProBadge
                                        show={shouldShowProBadge(FeatureType.SECURED_SHARING, presentation.getWorkspaceId())}
                                        analytics={{ cta: "SecuredSharing", ...presentation.getAnalytics() }}
                                        workspaceId={presentation.getWorkspaceId()}
                                        style={{ top: 5, right: 5 }}
                                    />
                                </div>
                            </Tooltip>
                            <Tooltip title="Anyone I invite or who has the link can view this presentation.">
                                <div
                                    className={
                                        `privacy-button ${privacy === PresentationPrivacyType.PUBLIC ? "selected" : ""}`
                                    }
                                    onClick={
                                        () => this.handlePrivacyChange(PresentationPrivacyType.PUBLIC)
                                    }
                                >
                                    <Icon iconName="public" />
                                    <label>Public</label>
                                </div>
                            </Tooltip>
                        </ColGrid>
                    </Section>

                    <Section title="How would you like to share this presentation?">
                        <Gap10 />
                        <ColGrid>
                            <Tooltip title="Beautiful.ai will send an email on your behalf.">
                                <div
                                    data-cy="send-invite-btn"
                                    className={
                                        `share-presentation-button ${privacy === PresentationPrivacyType.PRIVATE ? "disabled" : ""}`
                                    }
                                    onClick={
                                        () => this.handleShareCTA("Send Invite", CreateInviteDialog)
                                    }
                                >
                                    <Icon iconName="email" />
                                    <label>{
                                        privacy === PresentationPrivacyType.SECURED
                                            ? "Send Secure Invite" : "Send Invite"
                                    }</label>
                                </div>
                            </Tooltip>
                            <Tooltip title="Copy a shareable link to your clipboard which you can paste into your own email or share on a social network.">
                                <div
                                    className={
                                        `share-presentation-button ${privacy === PresentationPrivacyType.PRIVATE ? "disabled" : ""}`
                                    }
                                    onClick={() => this.handleShareCTA(
                                        privacy === PresentationPrivacyType.SECURED ? "Get Protected Link" : "Get Shareable Link",
                                        CreateLinkDialog,
                                        { linkType: privacy === PresentationPrivacyType.SECURED ? "protected" : "public" }
                                    )}
                                >
                                    <Icon iconName="link" />
                                    <label>{
                                        privacy === PresentationPrivacyType.SECURED
                                            ? "Get Protected Link" : "Get Link"
                                    }</label>
                                </div>
                            </Tooltip>
                            <Tooltip title="Embed your presentation to play on a website or blog.">
                                <div
                                    className={
                                        `share-presentation-button ${privacy !== PresentationPrivacyType.PUBLIC ? "disabled" : ""}`
                                    }
                                    onClick={
                                        () => this.handleShareCTA("Get Embed Code", CreateEmbedCodeDialog)
                                    }
                                >
                                    <Icon iconName="add_to_queue" />
                                    <label>Get Embed Code</label>
                                </div>
                            </Tooltip>
                            <Tooltip title="Quickly share your presentation on your favorite social network.">
                                <div
                                    className={
                                        `share-presentation-button ${privacy !== PresentationPrivacyType.PUBLIC ? "disabled" : ""}`
                                    }
                                    onClick={
                                        () => this.handleShareCTA("Social Share", SocialShareDialog)
                                    }
                                >
                                    <Icon iconName="share" />
                                    <label>Social Share</label>
                                </div>
                            </Tooltip>
                        </ColGrid>
                        <WarningWatermark
                            notCentered
                            cta="SharePaneWarningWatermark"
                            presentation={presentation}
                        />
                    </Section>

                    <Section title="Who can view this presentation?">
                        <LinksList
                            presentation={presentation}
                            linksUpdated={this.state.linksUpdated}
                            onLinksUpdated={this.handleResetLinksUpdated}
                        />
                    </Section>

                    <ConfirmationDialog
                        open={this.state.showPrivacyChangeDialog}
                        title={this.state.privacyChangeDialogTitle}
                        onAccept={this.handlePrivacyChangeDialogOk}
                        onCancel={this.handlePrivacyChangeDialogCancel}
                    >
                        {this.state.privacyChangeDialogMessage}
                    </ConfirmationDialog>
                </UIPaneContents>
            </UIPane>
        );
    }
}
