import type { EndpointDefinition } from "../_sdk/types/common";

import { endpoints as metadataEndpoints } from "./metadata/endpoints";
import { endpoints as workspaceEndpoints } from "./workspaces/endpoints";
import { endpoints as workspaceUsersEndpoints } from "./workspaceUsers/endpoints";
import { endpoints as workspaceUserGroupsEndpoints } from "./workspaceUserGroups/endpoints";
import { endpoints as workspacePermissionsEndpoints } from "./workspacePermissions/endpoints";
import { endpoints as workspaceResourceFoldersEndpoints } from "./workspaceResourceFolders/endpoints";
import { endpoints as subscriptionsEndpoints } from "./workspaceBilling/endpoints";

export const apiName = "workspaces";

export const endpoints: { [key: string]: EndpointDefinition } = {
    ...metadataEndpoints,
    ...workspaceEndpoints,
    ...workspaceUsersEndpoints,
    ...workspaceUserGroupsEndpoints,
    ...workspacePermissionsEndpoints,
    ...workspaceResourceFoldersEndpoints,
    ...subscriptionsEndpoints
};
