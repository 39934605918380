import { getCallable } from "../../_sdk/client";
import { apiName, endpoints } from "./endpoints";
import type {
    GetWorkspaceStripeDataRequest,
    GetWorkspaceStripeDataResponse,
    GetWorkspacePlanDataRequest,
    GetWorkspacePlanDataResponse,
    CancelSubscriptionRequest,
    CancelSubscriptionResponse,
    SwitchSubscriptionBillingIntervalRequest,
    SwitchSubscriptionBillingIntervalResponse,
    PreviewUpcomingInvoiceForBillingIntervalRequest,
    PreviewUpcomingInvoiceForBillingIntervalResponse,
    ReactivateSubscriptionRequest,
    ReactivateSubscriptionResponse,
    UpdatePaymentMethodRequest,
    UpdatePaymentMethodResponse,
    PayLatestInvoiceOnPastDueSubscriptionRequest,
    PayLatestInvoiceOnPastDueSubscriptionResponse,
    EndTrialOnSubscriptionRequest,
    EndTrialOnSubscriptionResponse,
    ApplyPromotionCodeToSubscriptionRequest,
    ApplyPromotionCodeToSubscriptionResponse,
    UpdateBillingDetailsRequest,
    UpdateBillingDetailsResponse
} from "./types";

export { apiName };

const getWorkspaceStripeData = getCallable<GetWorkspaceStripeDataRequest, GetWorkspaceStripeDataResponse>(
    apiName,
    "getWorkspaceBillingData",
    {
        ...endpoints.getWorkspaceBillingData,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/billing/stripe`,
                options: {
                    method: "GET"
                }
            };
        }
    });

const getWorkspacePlanData = getCallable<GetWorkspacePlanDataRequest, GetWorkspacePlanDataResponse>(
    apiName,
    "getWorkspacePlan",
    {
        ...endpoints.getWorkspaceBillingData,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/billing/plan`,
                options: {
                    method: "GET"
                }
            };
        }
    });

const cancelSubscription = getCallable<CancelSubscriptionRequest, CancelSubscriptionResponse>(
    apiName,
    "cancelSubscription",
    {
        ...endpoints.cancelSubscription,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/billing/subscriptions/cancel`,
                options: {
                    method: "POST"
                }
            };
        }
    });

const reactivateSubscription = getCallable<ReactivateSubscriptionRequest, ReactivateSubscriptionResponse>(
    apiName,
    "reactivateSubscription",
    {
        ...endpoints.reactivateSubscription,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/billing/subscriptions/reactivate`,
                options: {
                    method: "POST"
                }
            };
        }
    });

const switchSubscriptionBillingInterval = getCallable<SwitchSubscriptionBillingIntervalRequest, SwitchSubscriptionBillingIntervalResponse>(
    apiName,
    "switchSubscriptionBillingInterval",
    {
        ...endpoints.switchSubscriptionBillingInterval,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/billing/subscriptions/interval`,
                options: {
                    method: "POST",
                    body: JSON.stringify(request)
                }
            };
        }
    });

const previewUpcomingInvoiceForBillingInterval = getCallable<PreviewUpcomingInvoiceForBillingIntervalRequest, PreviewUpcomingInvoiceForBillingIntervalResponse>(
    apiName,
    "previewUpcomingInvoiceForBillingInterval",
    {
        ...endpoints.previewUpcomingInvoiceForBillingInterval,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/billing/invoices/preview/${request.billingInterval}`,
                options: {
                    method: "GET"
                }
            };
        }
    });

const updatePaymentMethod = getCallable<UpdatePaymentMethodRequest, UpdatePaymentMethodResponse>(
    apiName,
    "updatePaymentMethod",
    {
        ...endpoints.updatePaymentMethod,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/billing/payment-method`,
                options: {
                    method: "POST",
                    body: JSON.stringify(request)
                }
            };
        }
    });

const payLatestInvoiceOnPastDueSubscription = getCallable<PayLatestInvoiceOnPastDueSubscriptionRequest, PayLatestInvoiceOnPastDueSubscriptionResponse>(
    apiName,
    "payLatestInvoiceOnPastDueSubscription",
    {
        ...endpoints.payLatestInvoiceOnPastDueSubscription,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/billing/subscriptions/pay-latest-invoice`,
                options: {
                    method: "POST"
                }
            };
        }
    });

const endTrialOnSubscription = getCallable<EndTrialOnSubscriptionRequest, EndTrialOnSubscriptionResponse>(
    apiName,
    "endTrialOnSubscription",
    {
        ...endpoints.endTrialOnSubscription,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/billing/subscriptions/end-trial`,
                options: {
                    method: "POST"
                }
            };
        }
    });

const applyPromotionCodeToSubscription = getCallable<ApplyPromotionCodeToSubscriptionRequest, ApplyPromotionCodeToSubscriptionResponse>(
    apiName,
    "applyPromotionCodeToSubscription",
    {
        ...endpoints.applyPromotionCodeToSubscription,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/billing/subscriptions/promotion-code`,
                options: {
                    method: "POST",
                    body: JSON.stringify(request)
                }
            };
        }
    });

const updateBillingDetails = getCallable<UpdateBillingDetailsRequest, UpdateBillingDetailsResponse>(
    apiName,
    "updateBillingDetails",
    {
        ...endpoints.updateBillingDetails,
        composer: request => {
            return {
                url: `/workspaces/${request.workspaceId}/billing/customers/billing-details`,
                options: {
                    method: "POST",
                    body: JSON.stringify(request)
                }
            };
        }
    });

export const callables = {
    getWorkspaceStripeData,
    getWorkspacePlanData,
    cancelSubscription,
    reactivateSubscription,
    switchSubscriptionBillingInterval,
    previewUpcomingInvoiceForBillingInterval,
    updatePaymentMethod,
    payLatestInvoiceOnPastDueSubscription,
    endTrialOnSubscription,
    applyPromotionCodeToSubscription,
    updateBillingDetails
};
