import React, { Component } from "react";
import styled from "styled-components";

import { themeColors } from "../react/sharedStyles";
import { ds } from "../core/models/dataService";
import Spinner from "../react/components/Spinner";

const Container = styled.div`
    .MuiIcon-root {
        display: block;
    }
    display: flex;
    align-items: center;
    justify-content: center;
`;

const StyledIcon = styled.span`
    font-size: ${({ fontSize }) => fontSize}px;
    color: ${({ color }) => color ? `${color} !important` : null};
    font-variation-settings: ${({ fontVariationSettings }) => fontVariationSettings};
    opacity: ${({ opacity }) => opacity ?? 1};
    vertical-align: middle;
    cursor: ${({ onClick }) => onClick ? "pointer" : "unset"};
    transform: ${({ rotate }) => rotate ? `rotate(${rotate}deg)` : "none"};
`;

export class Icon extends Component {
    render() {
        const { blue, white, large, medium, rotate, small, size, color, fill, opacity, className, style = {}, onClick } = this.props;

        const children = React.Children.toArray(this.props.children).filter(Boolean);
        const child = children[0];

        let iconColor = "#777";
        let fontSize = 20;
        let fontVariationSettings = `'FILL' 0`;
        fontVariationSettings = `"FILL" 0, "wght" 400, "GRAD" 0, "opsz" 2`;

        if (color) {
            iconColor = color;
        } else if (blue) {
            iconColor = themeColors.ui_blue;
        } else if (white) {
            iconColor = "white";
        } else {
            iconColor = "#777";
        }

        if (large) {
            fontSize = 24;
        } else if (medium) {
            fontSize = 20;
        } else if (small) {
            fontSize = 16;
        } else if (size) {
            fontSize = size;
        }

        if (fill) {
            fontVariationSettings = `'FILL' 1`;
        }

        if (typeof child == "string") {
            return (
                <Container className={["icon", className].filter(Boolean).join(" ")}>
                    <StyledIcon
                        className="bai-icon material-symbols-outlined"
                        color={iconColor}
                        fontSize={fontSize}
                        fontVariationSettings={fontVariationSettings}
                        opacity={opacity}
                        style={style}
                        onClick={onClick}
                        rotate={rotate}
                    >
                        {child}
                    </StyledIcon>
                </Container>
            );
        } else {
            return <Container className={["icon", className].filter(Boolean).join(" ")}>{child}</Container>;
        }
    }
}

export class BeautifulAiIcon extends Component {
    state = {
        svgData: null
    }

    componentDidMount() {
        const child = React.Children.toArray(this.props.children)[0];

        ds.assets.getAssetById(child, "icon").then(icon => {
            const url = icon.get("original");
            if (url.startsWith("http")) {
                return fetch(url).then(res => {
                    return res.text();
                });
            } else {
                return Promise.resolve(url);
            }
        }).then(svgData => {
            this.setState({ svgData });
        });
    }

    render() {
        const { svgData } = this.state;

        if (svgData) {
            return <div dangerouslySetInnerHTML={{ __html: svgData }} />;
        } else {
            return <Spinner />;
        }
    }
}

