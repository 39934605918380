import { callables as metadataCallables } from "./metadata/callables";
import { callables as workspaceCallables } from "./workspaces/callables";
import { callables as workspaceUsersCallables } from "./workspaceUsers/callables";
import { callables as workspaceUserGroupsCallables } from "./workspaceUserGroups/callables";
import { callables as workspacePermissionsCallables } from "./workspacePermissions/callables";
import { callables as workspaceResourceFoldersCallables } from "./workspaceResourceFolders/callables";
import { callables as subscriptionsCallables } from "./workspaceBilling/callables";

export { apiName } from "./endpoints";

export const callables = {
    ...metadataCallables,
    ...workspaceCallables,
    ...workspaceUsersCallables,
    ...workspaceUserGroupsCallables,
    ...workspacePermissionsCallables,
    ...workspaceResourceFoldersCallables,
    ...subscriptionsCallables
};
