export class WindowManagement {
    static async getScreenDetails() {
        if (!window.screen.isExtended) {
            return false;
        }

        // check feature availability - https://caniuse.com/?search=getScreenDetails
        if (!("getScreenDetails" in window)) {
            return false;
        }

        let permissionGranted = await navigator.permissions.query({ name: "window-management" });
        let screenDetails = null;
        if (permissionGranted.state === "denied") {
            return false;
        } else {
            try {
                screenDetails = await window.getScreenDetails();
            } catch {
                return false;
            }
            return screenDetails;
        }
    }

    static preferredSecondaryScreen(screenDetails) {
        let preferredScreen = null;
        let possibleScreens = screenDetails.screens.filter(s => s != screenDetails.currentScreen);

        if (possibleScreens.length === 1) {
            preferredScreen = possibleScreens[0];
        } else {
            // choose closest screen on the right side
            preferredScreen = possibleScreens.toSorted((a, b) => a.left - b.left).find(s => (s.left > 0));
            if (!preferredScreen) {
                // choose closest screen on the left side
                preferredScreen = possibleScreens.toSorted((a, b) => a.left - b.left).reverse().find(s => (s.left < 0));
            }
            if (!preferredScreen) {
                // fall back to first screen in list
                preferredScreen = possibleScreens[0];
            }
        }
        return preferredScreen;
    }

    static openMaximizedWindow(screen, url, target = null, windowFeatures = "") {
        try {
            let screenWindowFeatures = `left=${screen.left},top=${screen.top},width=${screen.width},height=${screen.height}`;
            return window.open(url, target, [windowFeatures, screenWindowFeatures].join(","));
        } catch {
            return window.open(url, target, windowFeatures);
        }
    }
}
