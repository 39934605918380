import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
    ActionPermissionsObject,
    BrandingPermission,
    ITeamFirebase,
    IWorkspaceUserGroup,
    LegacyWorkspaceGroupIds,
    PermissionActionType,
    WorkspaceUserGroupType
} from "common/interfaces";
import getObjectHash from "common/utils/getObjectHash";
import { Breadcrumb } from "js/Components/Breadcrumb";
import { Dropdown } from "js/Components/Dropdown";
import { MenuItem } from "js/Components/Menu";
import { ToolTipBadge } from "js/Components/ToolTip";
import WorkspaceController, { WorkspaceControllerState } from "js/controllers/WorkspaceController";
import getLogger, { LogGroup } from "js/core/logger";
import { trackActivity } from "js/core/utilities/utilities";
import { ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import { UIPane } from "js/react/components/UiComponents";
import { SearchBarContainer, UIPaneResultsContainer } from "js/react/views/AddSlide/Panes/Components/SearchBox";
import FetchingClickShield from "js/react/components/FetchingClickShield";
import { isUserGroupLibrarians } from "common/utils/workspaces/userGroups";

const logger = getLogger(LogGroup.WORKSPACES);

const selectOptions = [
    {
        label: "All Members",
        value: BrandingPermission.ALL,
    },
    {
        label: "Librarians and Owners Only",
        value: BrandingPermission.OWNER_AND_LIB,
    },
    {
        label: "Nobody in this Workspace",
        value: BrandingPermission.NONE,
    },
];

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    font-size: 12px;
    font-weight: 600;
    color: #444;
    text-transform: uppercase;
    margin-bottom: 10px;

    >div {
        width: 230px;
    }
`;

const GuardrailContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 10px 20px;
    min-height: 60px;
    background: #fff;
    position: relative;
`;

const GuardrailLabel = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    font-size: 16px;
    font-weight: 600;
    color: #333;
`;

const GuardrailDropdown = styled(Dropdown)`
    &&& {
        width: 250px;
        border: none;

        .MuiSelect-select {
            color: black;
            font-size: 16px;
            font-weight: 600;
        }

        .MuiSvgIcon-root {            
            color: black;
        }

        fieldset {
            border: none;
        }
    }
`;

function Guardrail(props: {
    onChange: (value: BrandingPermission) => void;
    label: string;
    tooltip: string;
    value: BrandingPermission;
    disabled: boolean;
    fetching: boolean;
    "data-test-id"?: string;
}) {
    const {
        onChange,
        label,
        tooltip,
        value,
        disabled,
        fetching,
        "data-test-id": dataTestId
    } = props;

    const handleSelect = (selectedValue: BrandingPermission) => {
        if (value === selectedValue) {
            return;
        }

        onChange(selectedValue);
    };

    return (
        <GuardrailContainer data-test-id={dataTestId}>
            <FetchingClickShield visible={fetching} backgroundColor="rgba(255,255,255,0.5)" />
            <GuardrailLabel>
                <div>{label}</div>
                <ToolTipBadge>{tooltip}</ToolTipBadge>
            </GuardrailLabel>
            <GuardrailDropdown value={value} onChange={handleSelect} disabled={disabled}>
                {selectOptions.map(option => (
                    <MenuItem value={option.value} key={option.value} data-test-id={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </GuardrailDropdown>
        </GuardrailContainer>
    );
}

// Since we have to preserve the outer looks of the UI which matches the old data shape,
// we have to do this weird back and forth data transformation...
function getBrandingPermissions(userGroups: IWorkspaceUserGroup[]): ITeamFirebase["workspaceSettings"]["brandingPermissions"] {
    const allMembersGroup = userGroups.find(group => group.type === WorkspaceUserGroupType.ALL_WORKSPACE_MEMBERS);
    const librariansGroup = userGroups.find(group => isUserGroupLibrarians(group));

    const getPermission = (object: ActionPermissionsObject, action: PermissionActionType) =>
        allMembersGroup.actionPermissions[object][action]
            ? BrandingPermission.ALL
            : librariansGroup.actionPermissions[object][action]
                ? BrandingPermission.OWNER_AND_LIB
                : BrandingPermission.NONE;

    return {
        inspirationSlides: getPermission(ActionPermissionsObject.INSPIRATION_SLIDES, PermissionActionType.USE),
        customThemes: getPermission(ActionPermissionsObject.CUSTOM_THEMES, PermissionActionType.USE),
        stockLibraries: getPermission(ActionPermissionsObject.STOCK_LIBRARIES, PermissionActionType.USE),
        webImages: getPermission(ActionPermissionsObject.WEB_IMAGES, PermissionActionType.USE),
        classicSlides: getPermission(ActionPermissionsObject.CLASSIC_SLIDES, PermissionActionType.USE),
        convertToClassic: getPermission(ActionPermissionsObject.CONVERT_TO_CLASSIC, PermissionActionType.USE),
        importPPT: getPermission(ActionPermissionsObject.IMPORT_PPT, PermissionActionType.USE),
        exportPresentation: getPermission(ActionPermissionsObject.EXPORT_PRESENTATION, PermissionActionType.USE)
    };
}

function getGroupUpdates(object: ActionPermissionsObject, action: PermissionActionType, value: BrandingPermission) {
    return {
        [LegacyWorkspaceGroupIds.MEMBERS]: {
            actionPermissions: {
                [object]: {
                    [action]: value === BrandingPermission.ALL,
                },
            },
        },
        [LegacyWorkspaceGroupIds.LIBRARIANS]: {
            actionPermissions: {
                [object]: {
                    [action]: value === BrandingPermission.OWNER_AND_LIB,
                },
            },
        },
    };
}

export default WorkspaceController.withInitializedState(function TeamBrandingGuardrailsPane(props: WorkspaceControllerState) {
    const { userGroups } = props;

    const [fetchingField, setFetchingField] = useState<string | null>(null);
    const [brandingPermissions, setBrandingPermissions] = useState<ITeamFirebase["workspaceSettings"]["brandingPermissions"]>(getBrandingPermissions(userGroups));

    useEffect(() => {
        setBrandingPermissions(getBrandingPermissions(userGroups));
    }, [getObjectHash(userGroups)]);

    const handleChange = async (field: string, value: BrandingPermission) => {
        const updatedBrandingPermissions = {
            ...brandingPermissions,
            [field]: value,
        };
        setBrandingPermissions(updatedBrandingPermissions);

        trackActivity("TeamGuardrails", "GuardChange", null, null, {
            guard: field,
            permission: value,
        }, { audit: false });

        let groupUpdates: { [groupId: string]: { actionPermissions: Partial<IWorkspaceUserGroup["actionPermissions"]> } } = {};

        if (field === "inspirationSlides") {
            groupUpdates = getGroupUpdates(ActionPermissionsObject.INSPIRATION_SLIDES, PermissionActionType.USE, value);
        } else if (field === "customThemes") {
            groupUpdates = getGroupUpdates(ActionPermissionsObject.CUSTOM_THEMES, PermissionActionType.USE, value);
        } else if (field === "stockLibraries") {
            groupUpdates = getGroupUpdates(ActionPermissionsObject.STOCK_LIBRARIES, PermissionActionType.USE, value);
        } else if (field === "webImages") {
            groupUpdates = getGroupUpdates(ActionPermissionsObject.WEB_IMAGES, PermissionActionType.USE, value);
        } else if (field === "classicSlides") {
            groupUpdates = getGroupUpdates(ActionPermissionsObject.CLASSIC_SLIDES, PermissionActionType.USE, value);
        } else if (field === "convertToClassic") {
            groupUpdates = getGroupUpdates(ActionPermissionsObject.CONVERT_TO_CLASSIC, PermissionActionType.USE, value);
        } else if (field === "importPPT") {
            groupUpdates = getGroupUpdates(ActionPermissionsObject.IMPORT_PPT, PermissionActionType.USE, value);
        } else if (field === "exportPresentation") {
            groupUpdates = getGroupUpdates(ActionPermissionsObject.EXPORT_PRESENTATION, PermissionActionType.USE, value);
        }

        setFetchingField(field);
        try {
            await WorkspaceController.updateWorkspaceUserGroups({ update: groupUpdates });
        } catch (err) {
            logger.error(err, "Failed to update branding permissions");
            ShowErrorDialog({
                title: "Failed to update brand guardrails",
                message: "An error occurred while updating the brand guardrails. Please try again later.",
            });
        } finally {
            setFetchingField(null);
        }
    };

    const {
        inspirationSlides,
        customThemes,
        stockLibraries,
        webImages,
        classicSlides,
        convertToClassic,
        importPPT,
        exportPresentation
    } = brandingPermissions;

    return (
        <UIPane>
            <SearchBarContainer className="search-container">
                <Breadcrumb>Brand Guardrails</Breadcrumb>
            </SearchBarContainer>
            <UIPaneResultsContainer>
                <Container>
                    <HeaderContainer>
                        <div>Feature</div>
                        <div>Who has access?</div>
                    </HeaderContainer>
                    <Guardrail
                        label="Built-in Starter Templates and Inspiration Slides"
                        tooltip="Decide who on your team has access to our pre-designed starter materials."
                        value={inspirationSlides}
                        onChange={value => handleChange("inspirationSlides", value)}
                        disabled={!!fetchingField}
                        fetching={fetchingField === "inspirationSlides"}
                        data-test-id="inspirationSlides"
                    />
                    <Guardrail
                        label="Built-in and Custom Themes"
                        tooltip="Decide who on your team has access to themes outside of your Team Theme."
                        value={customThemes}
                        onChange={value => handleChange("customThemes", value)}
                        disabled={!!fetchingField}
                        fetching={fetchingField === "customThemes"}
                        data-test-id="customThemes"
                    />
                    <Guardrail
                        label="Stock Image and Video Libraries"
                        tooltip="Decide who on your team has access to our stock media library, in addition to Team Assets, icons, logos and media upload."
                        value={stockLibraries}
                        onChange={value => handleChange("stockLibraries", value)}
                        disabled={!!fetchingField}
                        fetching={fetchingField === "stockLibraries"}
                        data-test-id="stockLibraries"
                    />
                    <Guardrail
                        label="Web Images"
                        tooltip="Decide who on your team has access to our web image search, in addition to Team Assets, icons, logos, and media upload."
                        value={webImages}
                        onChange={value => handleChange("webImages", value)}
                        disabled={!!fetchingField}
                        fetching={fetchingField === "webImages"}
                        data-test-id="webImages"
                    />
                    <Guardrail
                        label="Classic Slides"
                        tooltip="Decide who on your team can design a slide from scratch with our blank, Classic slide."
                        value={classicSlides}
                        onChange={value => handleChange("classicSlides", value)}
                        disabled={!!fetchingField}
                        fetching={fetchingField === "classicSlides"}
                        data-test-id="classicSlides"
                    />
                    <Guardrail
                        label="Convert to Classic"
                        tooltip="Decide who on your team can remove design guardrails by converting Smart Slides to Classic."
                        value={convertToClassic}
                        onChange={value => handleChange("convertToClassic", value)}
                        disabled={!!fetchingField}
                        fetching={fetchingField === "convertToClassic"}
                        data-test-id="convertToClassic"
                    />
                    <Guardrail
                        label="PowerPoint Import"
                        tooltip="Decide who on your team can import PowerPoint slides into presentations."
                        value={importPPT}
                        onChange={value => handleChange("importPPT", value)}
                        disabled={!!fetchingField}
                        fetching={fetchingField === "importPPT"}
                        data-test-id="importPPT"
                    />
                    <Guardrail
                        label="Export"
                        tooltip="Decide who on your team can export slides to PowerPoint, Google Slides, PDF or as an image. If export is restricted, presentations can only be shared via tracked link, email or embed."
                        value={exportPresentation}
                        onChange={value => handleChange("exportPresentation", value)}
                        disabled={!!fetchingField}
                        fetching={fetchingField === "exportPresentation"}
                        data-test-id="exportPresentation"
                    />
                </Container>
            </UIPaneResultsContainer>
        </UIPane>
    );
});
