import "css/add-slide.scss";

import React, { Component } from "react";
import { $, _ } from "js/vendor";
import { ds } from "js/core/models/dataService";
import { Key } from "js/core/utilities/keys";
import { FeatureType } from "js/core/models/features";
import { trackActivity } from "js/core/utilities/utilities";
import { appVersion } from "js/config";
import { getCanvasBundle } from "js/canvas";

import { UpgradePlanDialogType } from "js/react/views/MarketingDialogs/UpgradePlanDialog";
import { SearchSlidesPane } from "js/react/views/AddSlide/Panes/SearchSlidesPane";
import { AddSlideController } from "js/react/views/AddSlide/AddSlideController";
import {
    Divider,
    UIContainer,
    UINavigation,
    UINavigationItem
} from "js/react/components/UiComponents";
import { ShowErrorDialog } from "js/react/components/Dialogs/BaseDialog";
import { CopySlidePane } from "./Panes/CopySlidePane";
import ProBadge from "js/react/components/ProBadge";
import AppController from "js/core/AppController";
import PresentationEditorController from "js/editor/PresentationEditor/PresentationEditorController";

import { SlideGalleryPane } from "./Panes/SlideGalleryPane";
import { TeamResourceTypes } from "js/react/views/TeamResources/TeamResources";
import { ImportSlidesFromPPTPane } from "./Panes/ImportSlidesFromPPTPane";
import { TeamTemplatePane } from "./Panes/TeamTemplatePane";
import DesignerBotIcon from "js/react/components/DesignerBotIcon";
import GenerateSlidePane from "js/react/views/AIGeneration/panes/GenerateSlidePane/GenerateSlidePane";
import withProgressDialog from "js/core/utilities/withProgressDialog";

class AddSlideContainer extends Component {
    state = {
        selectedPaneId: "search",
        showUserTheme: false
    };

    constructor() {
        super();

        this.searchSlidesPaneRef = React.createRef();
    }

    componentDidMount() {
        $(window).on("keydown.add-slide-container", event => {
            if (event.which == Key.ESCAPE) {
                this.handleBackButton();
            }
        });
    }

    componentWillUnmount() {
        $(window).off("keydown.add-slide-container");
    }

    handleSelectPane = paneId => {
        if (paneId == "search" && this.state.selectedPaneId == "search") {
            if (this.searchSlidesPaneRef.current.state.query != "") {
                this.searchSlidesPaneRef.current.handleClearSearch();
                return;
            }
            if (this.searchSlidesPaneRef.current.state.selectedTemplate != null) {
                this.searchSlidesPaneRef.current.setState({ selectedTemplate: null });
                return;
            }
        }

        this.setState({ selectedPaneId: paneId });
    }

    handleBackButton = () => {
        const { closeDialog } = this.props;

        if (this.state.selectedPresentationId) {
            this.setState({
                selectedPresentationId: null
            });
            return;
        }

        if (this.state.selectedPaneId == "search" && this.searchSlidesPaneRef.current.state.query != "") {
            this.searchSlidesPaneRef.current.handleClearSearch();
            return;
        }
        if (this.state.selectedPaneId == "search" && this.searchSlidesPaneRef.current.state.selectedTemplate != null) {
            this.searchSlidesPaneRef.current.setState({
                selectedTemplate: null
            });
            return;
        }

        closeDialog(false);
    }

    handleAddSlideFromTemplate = async template => {
        if (this.isClosing) return;
        this.isClosing = true;

        await AddSlideController.addSlideFromTemplate(template, this.props.replaceSlide);
        _.defer(() => {
            this.props.closeDialog(true);
        });
    }

    handleAddSlideFromTeamSlideTemplate = async teamSlideTemplates => {
        if (this.isClosing) return;
        this.isClosing = true;

        await withProgressDialog({
            title: "Adding selected slides to presentation",
            action: async () => {
                await Promise.all(teamSlideTemplates.map(async teamSlideTemplate => {
                    await AddSlideController.addSlideFromTeamSlideTemplate(teamSlideTemplate);
                }));
            }
        });
        _.defer(() => {
            this.props.closeDialog(true);
        });
    }

    handleToggleUserTheme = () => {
        this.setState({ showUserTheme: !this.state.showUserTheme });
    }

    handleAddBlankClassicSlide = async () => {
        const { slideTemplates: { classicTemplates } } = await getCanvasBundle(window.FORCE_NEW_SLIDES_VERSION ?? appVersion);
        const blank = classicTemplates.find(template => template.id === "blank");
        return this.handleAddSlideFromClassicTemplate(blank);
    }

    handleAddSlideFromClassicTemplate = async template => {
        if (this.isClosing) return;
        this.isClosing = true;

        const slideModel = {
            template_id: "authoring",
            layout: {
                headerPosition: template.headerPosition ?? "none",
                showFooter: "off"
            },
            states: [{
                primary: template.model,
                header: template.headerModel
            }],
            version: template.version,
            migrationVersion: template.migrationVersion
        };

        AddSlideController.addSlideFromModel(slideModel);

        trackActivity("ClassicTemplate", "Selected", template.id);

        _.defer(() => {
            this.props.closeDialog(true);
        });
    }

    handleCopySlidesFromInspirationGallery = async slide => {
        if (this.isClosing) return;
        this.isClosing = true;

        try {
            await AddSlideController.copySlidesIntoPresentation([slide], this.props.replaceSlide, "gallery");
            this.props.closeDialog(true);
        } catch (err) {
            ShowErrorDialog({
                title: "Unable to copy slide into presentation",
                message: err.message
            });
            this.isClosing = false;
        }
    }

    handleCopySlidesFromPresentation = async slides => {
        if (this.isClosing) return;
        this.isClosing = true;

        try {
            await AddSlideController.copySlidesIntoPresentation(slides, this.props.replaceSlide, "presentation");
            this.props.closeDialog(true);
        } catch (err) {
            ShowErrorDialog({
                title: "Unable to copy slide into presentation",
                message: err.message
            });
            this.isClosing = false;
        }
    }

    handleCopySlideFromLibrary = async teamSlidesDataServiceDataItems => {
        if (this.isClosing) return;
        this.isClosing = true;

        try {
            await withProgressDialog({
                title: "Adding selected slides to presentation",
                action: async () => {
                    await Promise.all(teamSlidesDataServiceDataItems.map(async teamSlidesDataServiceDataItem => {
                        await AddSlideController.copySlideFromLibraryIntoPresentation(teamSlidesDataServiceDataItem.id, teamSlidesDataServiceDataItem.libraryItemId);
                    }));
                }
            });
            this.props.closeDialog(true);
        } catch (err) {
            ShowErrorDialog({
                title: "Unable to add Team Slide to presentation",
                message: err.message
            });
            this.isClosing = false;
        }
    }

    handlePPTImportFinished = () => {
        if (this.isClosing) return;
        this.isClosing = true;
        if (PresentationEditorController.currentSlide) {
            PresentationEditorController.goNextSlide();
        }
        this.props.closeDialog(true);
    }

    handleGeneratedSlide = async (slideModel, prompt, aiResponse) => {
        if (this.isClosing) return;
        this.isClosing = true;

        const slides = await AddSlideController.addSlideFromModel(slideModel);

        trackActivity("Slide", "Generate", null, null, {
            presentationId: slides[0].id,
            prompt,
            aiResponse: JSON.stringify(aiResponse)
        });

        _.defer(() => {
            this.props.closeDialog(true);
        });
    }

    renderSelectedPane = () => {
        const { forTeamSlide = false } = this.props;
        const { selectedPaneId } = this.state;

        let organizationId = AppController.orgId;
        let workspaceId = AppController.workspaceId;
        // collaboration from an org-owned presentation to a user not in org should show as personal
        if (!AppController.workspacesMetadata.find(ws => ws.id === organizationId)) {
            organizationId = null;
            workspaceId = "personal";
        }

        trackActivity("AddSlide", "PaneSelected", null, null, { type: selectedPaneId }, { audit: false, skipAmplitude: true });

        switch (selectedPaneId) {
            case "slide-gallery":
                return (
                    <SlideGalleryPane
                        onSelected={this.handleCopySlidesFromInspirationGallery}
                        onAddBlankClassicSlide={this.handleAddBlankClassicSlide}
                        showUserTheme={this.state.showUserTheme}
                        workspaceId={workspaceId}
                        searchType="SlideGalleryPane"
                    />
                );
            case "copy-slide":
                return (
                    <CopySlidePane
                        forTeamSlide={this.props.forTeamSlide}
                        onSelected={this.handleCopySlidesFromPresentation}
                        onAddBlankClassicSlide={this.handleAddBlankClassicSlide}
                        showUserTheme={this.state.showUserTheme}
                        organizationId={organizationId}
                    />
                );
            case "slide-team-templates":
                return (
                    <TeamTemplatePane
                        isTemplate={true}
                        resourceType={TeamResourceTypes.SLIDE_TEMPLATES}
                        trackActivity="slideTemplateLibrary"
                        organizationId={organizationId}
                        workspaceId={workspaceId}
                        searchType="TeamSlideLibraryPane"
                        onSelected={this.handleAddSlideFromTeamSlideTemplate}
                        onToggleUserTheme={this.handleToggleUserTheme}
                        showUserTheme={this.state.showUserTheme}
                        onAddBlankClassicSlide={this.handleAddBlankClassicSlide}
                    />
                );
            case "slide-library":
                return (
                    <TeamTemplatePane
                        isTemplate={false}
                        resourceType={TeamResourceTypes.SHARED_SLIDES}
                        trackActivity="SharedSlideLibrary"
                        organizationId={organizationId}
                        workspaceId={workspaceId}
                        searchType="TeamSlideLibraryPane"
                        onSelected={this.handleCopySlideFromLibrary}
                        showUserTheme={this.state.showUserTheme}
                        onAddBlankClassicSlide={this.handleAddBlankClassicSlide}
                    />
                );
            case "ppt-import":
                return (
                    <ImportSlidesFromPPTPane
                        forTeamSlide={this.props.forTeamSlide}
                        onImportFinished={this.handlePPTImportFinished}
                    />
                );
            case "ai-generate":
                return (
                    <GenerateSlidePane onGeneratedSlide={this.handleGeneratedSlide} />
                );
            case "search":
            default:
                return (
                    <SearchSlidesPane
                        forTeamSlide={forTeamSlide}
                        ref={this.searchSlidesPaneRef}
                        organizationId={organizationId}
                        onTemplateSelected={this.handleAddSlideFromTemplate}
                        onClassicTemplateSelected={this.handleAddSlideFromClassicTemplate}
                        onGallerySlideSelected={this.handleCopySlidesFromInspirationGallery}
                        onCopySlideSelected={this.handleCopySlidesFromPresentation}
                        onTeamSlideSelected={this.handleCopySlideFromLibrary}
                        onTeamSlideTemplateSelected={this.handleAddSlideFromTeamSlideTemplate}
                        onSelectViewAll={this.handleSelectPane}
                        onAddBlankClassicSlide={this.handleAddBlankClassicSlide}
                        onToggleUserTheme={this.handleToggleUserTheme}
                        // To show always thumbnails with the current theme
                        showUserTheme={true}
                        hideUseCurrentThemeSwitch={this.props.hideUseCurrentThemeSwitch}
                    />
                );
        }
    }

    render() {
        const { closeDialog, hidePPTImport = false, forceShowTeamSlides = false } = this.props;
        const { selectedPaneId } = this.state;

        const workspaceId = AppController.workspaceId;

        const showTeamSlidesPane = (forceShowTeamSlides || (!PresentationEditorController.isSingleSlideEditor));

        const designerBotHidden = AppController.user.features.isFeatureEnabled(FeatureType.PROHIBIT_GENERATIVE_AI, workspaceId);
        const designerBotAccessible = AppController.user.features.isFeatureEnabled(FeatureType.DESIGNER_BOT, workspaceId);
        const pptImportHidden = hidePPTImport || !AppController.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_IMPORT_PPT, workspaceId);

        return (
            <UIContainer isOpen={true} className="add-slide-container" onClose={closeDialog}
                direction="horizontal">
                <UINavigation
                    selected={selectedPaneId}
                    onSelect={id => this.handleSelectPane(id)}
                    onBack={this.handleBackButton}
                >
                    <UINavigationItem
                        key="search"
                        id="search"
                        title="Search For A Slide"
                        icon="search"
                    />
                    {!designerBotHidden &&
                        <Divider margin={10} color="#555" />
                    }
                    {!designerBotHidden &&
                        <UINavigationItem
                            key="ai"
                            id="ai-generate"
                            title="Generate with AI..."
                            image={<DesignerBotIcon />}
                            proBadge={
                                !designerBotAccessible &&
                                <ProBadge
                                    upgradeType={UpgradePlanDialogType.UPGRADE_PLAN}
                                    show={!designerBotAccessible}
                                    analytics={{ cta: "SlideBot", ...ds.selection?.presentation?.getAnalytics() }}
                                    workspaceId={workspaceId}
                                />
                            }
                        />
                    }
                    {
                        AppController.user.features.isFeatureEnabled(FeatureType.WORKSPACE_CAN_ACCESS_INSPIRATION_SLIDES, workspaceId) &&
                        <UINavigationItem
                            key="slide-gallery"
                            id="slide-gallery"
                            title="Get Inspiration"
                            icon="lightbulb"
                        />
                    }
                    {
                        // Adding components in an array in order to avoid wrapping it with an empty fragment or have three conditions
                        showTeamSlidesPane && ([
                            <Divider color="#555" margin={10} key="divider" />,
                            <UINavigationItem
                                key="slide-team-templates"
                                id="slide-team-templates"
                                title="Slide Templates"
                                icon="library_add"
                                proBadge={
                                    <ProBadge
                                        upgradeType={UpgradePlanDialogType.TEAM_NOOP}
                                        show={!AppController.user.features.isFeatureEnabled(FeatureType.VIEW_LIBRARY_ITEMS, workspaceId)}
                                        analytics={{ cta: "SharedSlides", ...ds.selection.presentation.getAnalytics() }}
                                        workspaceId={workspaceId}
                                    />
                                }
                            />,
                            <UINavigationItem
                                key="slide-library"
                                id="slide-library"
                                title="Shared Slides"
                                icon="people"
                                proBadge={
                                    <ProBadge
                                        upgradeType={UpgradePlanDialogType.TEAM_NOOP}
                                        show={!AppController.user.features.isFeatureEnabled(FeatureType.VIEW_LIBRARY_ITEMS, workspaceId)}
                                        analytics={{ cta: "SharedSlides", ...ds.selection.presentation.getAnalytics() }}
                                        workspaceId={workspaceId}
                                    />
                                }
                            />
                        ].map(item => item))
                    }
                    {<Divider color="#555" margin={10} />}
                    {
                        <UINavigationItem
                            key="copy-slide"
                            id="copy-slide"
                            title="My Slides"
                            icon="file_copy"
                        />
                    }
                    {!pptImportHidden && <Divider color="#555" margin={10} />}
                    {!pptImportHidden &&
                        <UINavigationItem
                            key="ppt-import"
                            id="ppt-import"
                            title="Import Slides From PPT"
                            icon="upload"
                        />
                    }
                </UINavigation>
                {this.renderSelectedPane()}
            </UIContainer>
        );
    }
}

export default AppController.withState(AddSlideContainer);

