import { getCallable } from "../_sdk/client";
import { apiName, endpoints } from "./endpoints";
import {
    GetSlideRequest,
    GetSlideResponse,
    CreateSlideRequest,
    CreateSlideResponse,
    UpdateSlideRequest,
    UpdateSlideResponse,
    GetSlideRevisionsRequest,
    GetSlideRevisionsResponse,
    GetSlideOwnerRequest,
    GetSlideOwnerResponse,
    MigrateModelRequest,
    MigrateModelResponse
} from "./types";

export { apiName };

const getSlide = getCallable<GetSlideRequest, GetSlideResponse>(apiName, "getSlide", {
    ...endpoints.getSlide,
    composer: request => {
        const {
            id,
            presentationId,
            presentationLinkId,
            isReadOnlyMode
        } = request;

        const searchParams = new URLSearchParams();
        presentationId && searchParams.set("presentationId", presentationId);
        presentationLinkId && searchParams.set("presentationLinkId", presentationLinkId);
        isReadOnlyMode && searchParams.set("isReadOnlyMode", isReadOnlyMode ? "true" : "false");

        return {
            url: `/slides/${encodeURIComponent(id)}?${searchParams.toString()}`,
            options: {
                method: "GET"
            }
        };
    }
});

const createSlide = getCallable<CreateSlideRequest, CreateSlideResponse>(apiName, "createSlide", {
    ...endpoints.createSlide,
    composer: request => {
        return {
            url: `/slides/${encodeURIComponent(request.id)}`,
            options: {
                method: "POST",
                body: JSON.stringify(request)
            }
        };
    }
});

const updateSlide = getCallable<UpdateSlideRequest, UpdateSlideResponse>(apiName, "updateSlide", {
    ...endpoints.updateSlide,
    composer: request => {
        return {
            url: `/slides/${encodeURIComponent(request.id)}`,
            options: {
                method: "PUT",
                body: JSON.stringify(request)
            }
        };
    }
});

const getSlideRevisions = getCallable<GetSlideRevisionsRequest, GetSlideRevisionsResponse>(apiName, "getSlideRevisions", {
    ...endpoints.getSlideRevisions,
    composer: request => {
        const { id, page, pageSize } = request;

        const searchParams = new URLSearchParams();
        if (typeof page === "number") {
            searchParams.set("page", page.toString());
        }
        if (typeof pageSize === "number") {
            searchParams.set("pageSize", pageSize.toString());
        }

        return {
            url: `/slides/${encodeURIComponent(id)}/revisions?${searchParams.toString()}`,
            options: {
                method: "GET"
            }
        };
    }
});

const getSlideOwner = getCallable<GetSlideOwnerRequest, GetSlideOwnerResponse>(apiName, "getSlideOwner", {
    ...endpoints.getSlideOwner,
    composer: request => {
        const { id, presentationId } = request;

        const searchParams = new URLSearchParams();
        searchParams.set("presentationId", presentationId);

        return {
            url: `/slides/${encodeURIComponent(id)}/owner?${searchParams.toString()}`,
            options: {
                method: "GET"
            }
        };
    }
});

const migrateModel = getCallable<MigrateModelRequest, MigrateModelResponse>(apiName, "migrateModel", {
    ...endpoints.migrateModel,
    composer: request => {
        return {
            url: `/slides/migrate/model`,
            options: {
                method: "POST",
                body: JSON.stringify(request)
            }
        };
    }
});

export const callables = {
    getSlide,
    createSlide,
    updateSlide,
    getSlideRevisions,
    getSlideOwner,
    migrateModel
};
