import React, { Component } from "reactn";
import styled from "styled-components";
import { DialogTitle, DialogActions, Button, FormControlLabel, Checkbox } from "@material-ui/core";

import { _ } from "js/vendor";
import { app } from "js/namespaces";
import { getStaticUrl } from "js/config";
import { ScaryButton } from "js/react/components/UiComponents";
import { FlexSpacer } from "js/react/components/Gap";
import DialogContent from "js/react/components/Dialogs/DialogContent";
import BeautifulDialog from "js/react/components/Dialogs/BeautifulDialog";

const Robot = styled.div`
  width: 100%;
  height: 120px;
  margin-bottom: -30px;
  text-align: left;
  padding-left: 20px;
  padding-top: 10px;
  img {
    object-fit: contain;
    height: 100%;
  }
`;

export default class ConfirmationScaryDialog extends Component {
    state = {
        optOut: false
    }

    componentDidMount = () => {
        const { optOutProperty, closeDialog } = this.props;

        // Immediately close the dialog with a confirmation
        //   response if the user has opted out of this dialog
        if (app.user?.get("messagesSeen")?.[optOutProperty]) {
            closeDialog(true);
        }
    }

    handleChangeOptOutCheckbox = event => {
        this.setState({
            optOut: !!event.target.checked
        });
    }

    handleCloseDialog = result => {
        const { optOutProperty, closeDialog } = this.props;
        const { optOut } = this.state;

        if (optOutProperty) {
            const messagesSeen = _.cloneDeep(app.user?.get("messagesSeen")) ?? {};
            if (optOut) {
                messagesSeen[optOutProperty] = true;
            } else {
                delete messagesSeen[optOutProperty];
            }
            app.user?.update({ messagesSeen });
        }

        closeDialog(result);
    }

    render() {
        const {
            title,
            message,
            warning,
            cancelCallback,
            cancelButtonLabel,
            acceptCallback,
            okButtonLabel,
            buttonOptions = {},
            showRobot,
            optOutProperty,
            align
        } = this.props;
        const { optOut } = this.state;

        const classes = warning ? { paper: "error-dialog" } : null;

        return (
            <BeautifulDialog
                classes={classes}
                closeDialog={() => this.handleCloseDialog(false)}
                style={{ textAlign: align }}
            >
                {showRobot && <Robot><img src={getStaticUrl(`/images/bai-bot/bai-bot-shake.gif`)}></img></Robot>}
                <DialogTitle data-test-id="confirmation-dialog-title">{title}</DialogTitle>
                <DialogContent data-test-id="confirmation-dialog-message">{message}</DialogContent>
                <DialogActions>
                    {!!optOutProperty &&
                        <FormControlLabel
                            label="Don't show me this again"
                            control={
                                <Checkbox checked={optOut} onChange={this.handleChangeOptOutCheckbox} />
                            }
                        />
                    }
                    <FlexSpacer />
                    <Button
                        data-test-id="confirmation-dialog-cancel-button"
                        onClick={() => {
                            cancelCallback && cancelCallback();
                            this.handleCloseDialog(false);
                        }}
                    >
                        {cancelButtonLabel ?? "Cancel"}
                    </Button>
                    {
                        buttonOptions.acceptButtonColor === "red" ? (
                            <ScaryButton
                                data-test-id="confirmation-dialog-ok-button"
                                onClick={() => {
                                    acceptCallback && acceptCallback();
                                    this.handleCloseDialog(true);
                                }}
                            >
                                {okButtonLabel ?? "Ok"}
                            </ScaryButton>
                        ) : (
                            <Button
                                color="primary"
                                data-test-id="confirmation-dialog-ok-button"
                                onClick={() => {
                                    acceptCallback && acceptCallback();
                                    this.handleCloseDialog(true);
                                }}
                            >
                                {okButtonLabel ?? "Ok"}
                            </Button>
                        )
                    }
                </DialogActions>
            </BeautifulDialog>
        );
    }
}
