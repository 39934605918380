import _ from "lodash";

export enum WorkspacePlanTier {
    BASIC = "basic",
    PRO = "pro",
    TEAM = "team",
    ENTERPRISE = "enterprise"
}

export const WORKSPACE_PLAN_NAMES: Record<WorkspacePlanTier, string> = {
    [WorkspacePlanTier.BASIC]: "Basic",
    [WorkspacePlanTier.PRO]: "Pro",
    [WorkspacePlanTier.TEAM]: "Team",
    [WorkspacePlanTier.ENTERPRISE]: "Enterprise"
};

export interface IWorkspacePlan {
    tier: WorkspacePlanTier;
    name: string;
    requiresSubscription: boolean;
}

const DEFAULT_BASIC_PLAN: IWorkspacePlan = {
    tier: WorkspacePlanTier.BASIC,
    name: WORKSPACE_PLAN_NAMES[WorkspacePlanTier.BASIC],
    requiresSubscription: false
};

export function GET_DEFAULT_BASIC_PLAN(): IWorkspacePlan {
    return _.cloneDeep(DEFAULT_BASIC_PLAN);
}
