import _ from "lodash";

import {
    IWorkspaceUserGroupActionPermissions,
    GET_DEFAULT_WORKSPACE_RESTRICTED_ACTION_PERMISSIONS
} from "common/interfaces";

export function ensureActionPermissionsAreMoreRestricted(actionPermissions: IWorkspaceUserGroupActionPermissions, actionPermissionsToCompare: IWorkspaceUserGroupActionPermissions) {
    const restrictedActionPermissions = _.cloneDeep<IWorkspaceUserGroupActionPermissions>(actionPermissions);

    let hasChanges = false;
    Object.keys(restrictedActionPermissions).forEach(actionPermissionKey => {
        Object.keys(restrictedActionPermissions[actionPermissionKey]).forEach(action => {
            if (!actionPermissionsToCompare[actionPermissionKey][action] && restrictedActionPermissions[actionPermissionKey][action]) {
                restrictedActionPermissions[actionPermissionKey][action] = false;
                hasChanges = true;
            }
        });
    });

    if (hasChanges) {
        return restrictedActionPermissions;
    }

    return null;
}

export function getCombinedActionPermissions(actionPermissions: IWorkspaceUserGroupActionPermissions[]) {
    const combinedActionPermissions = GET_DEFAULT_WORKSPACE_RESTRICTED_ACTION_PERMISSIONS();

    actionPermissions
        .forEach(actionPermissions => {
            Object.keys(actionPermissions).forEach(key => {
                Object.keys(actionPermissions[key]).forEach(action => {
                    combinedActionPermissions[key][action] = combinedActionPermissions[key][action] || actionPermissions[key][action];
                });
            });
        });

    return combinedActionPermissions;
}
