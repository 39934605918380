import React, { } from "react";
import styled from "styled-components";

import { Icon, IconButton, MenuItem } from "@material-ui/core";

import { FlexBox } from "js/react/components/LayoutGrid";
import {
    AddContextPopupMenu,
    ContextBillingNote,
    ContextNote,
    ContextSourceContainer
} from "js/react/views/AIGeneration/components/PaneComponents";
import FileInput from "js/react/views/AIGeneration/components/FileInput";
import DocumentTextInput from "js/react/views/AIGeneration/components/DocumentTextInput";
import WebpageUrlInput from "js/react/views/AIGeneration/components/WebpageUrlInput";
import { Gap20 } from "js/react/components/Gap";
import { Divider } from "js/react/components/UiComponents";
import { ContextSource, ContextSourceType } from "js/react/views/AIGeneration/panes/GeneratePresentationPane/controllers/GeneratePresentationController";
import { EndTrialDialog } from "js/react/views/UserOptions/Billing/EndTrialDialog";
import { ShowDialogAsync } from "js/react/components/Dialogs/BaseDialog";

const InsufficientCreditsWarning = styled.div`
    background: orangered;
    padding: 12px;
    color: white;
    font-size: 15px;
    font-weight: bold;

    >a {
        color: white;
        text-decoration: underline;
    }  
`;

interface ContextSourcesProps {
    contextSources: ContextSource[];
    aiCreditsBalance: number;
    onAdd: (contextSourceType: ContextSourceType) => void;
    onDelete: (contextSource: ContextSource) => void;
    reloadAiCreditsBalance: () => void;
    requiredCredits?: number;
}

export function ContextSources(props: ContextSourcesProps) {
    const { contextSources, aiCreditsBalance, onAdd, onDelete, reloadAiCreditsBalance, requiredCredits = 1 } = props;

    const isPaidUser = aiCreditsBalance === -1;
    const hasSufficientCredits = isPaidUser || (aiCreditsBalance >= requiredCredits);

    const handleEndTrial = () => {
        ShowDialogAsync(EndTrialDialog)
            .then(() => reloadAiCreditsBalance());
    };

    if (contextSources.length > 0) {
        return (
            <FlexBox left top fillWidth vertical gap={20} style={{ flexGrow: 2 }}>
                {/* @ts-ignore */}
                <Divider color="#d1d1d1" margin={0} />
                {isPaidUser &&
                    <FlexBox fillWidth left middle gap={20}>
                        <ContextNote style={{ flexShrink: 0 }}>
                            <b>AI Credits Remaining: Unlimited during beta</b>
                        </ContextNote>
                        <ContextBillingNote>
                            During the beta period, you are limited to 200 generated slides with context per day.<br />Pricing and
                            availability of this feature may change in the future.
                        </ContextBillingNote>
                    </FlexBox>
                }
                {!isPaidUser &&
                    <FlexBox fillWidth left middle gap={20}>
                        <ContextNote style={{ flexShrink: 0 }}>
                            <b>AI Credits Remaining: {aiCreditsBalance}</b>
                        </ContextNote>
                        <ContextBillingNote>
                            During your trial, you are limited to 100 free AI credits when adding context to
                            a prompt. Each generated slide with added context costs 1 credit.<br />Pricing and
                            availability of this feature may change in the future.
                        </ContextBillingNote>
                    </FlexBox>
                }
                {hasSufficientCredits && contextSources.map((contextSource, idx) => (
                    <ContextSourceContainer key={idx}>
                        {contextSource.type == ContextSourceType.FILE &&
                            <FileInput contextSource={contextSource} />}
                        {contextSource.type == ContextSourceType.TEXT &&
                            <DocumentTextInput contextSource={contextSource} />}
                        {contextSource.type == ContextSourceType.WEBPAGE &&
                            <WebpageUrlInput contextSource={contextSource} />}
                        <div>
                            <IconButton size="small"
                                onClick={() => onDelete(contextSource)}>
                                <Icon>close</Icon>
                            </IconButton>
                        </div>
                    </ContextSourceContainer>
                ))}
                {!hasSufficientCredits && <InsufficientCreditsWarning>
                    You don't have sufficient trial AI credits to use this feature
                    anymore.<br />
                    <a onClick={handleEndTrial}>Start your subscription now</a> for continued access to this feature.
                </InsufficientCreditsWarning>}
            </FlexBox >
        );
    }

    return (
        <FlexBox fillWidth left top vertical gap={20}>
            {/* @ts-ignore */}
            <Divider color="#d1d1d1" margin={0} />
            <FlexBox left fillWidth>
                <AddContextPopupMenu label="Train AI with Additional Context"
                    showArrow
                    childrenAreMenuItems
                    onChange={onAdd}>
                    <MenuItem value={ContextSourceType.FILE}>
                        <Icon>text_snippet</Icon>Upload File
                    </MenuItem>
                    <MenuItem value={ContextSourceType.WEBPAGE}>
                        <Icon>language</Icon>Webpage
                    </MenuItem>
                    <MenuItem value={ContextSourceType.TEXT}>
                        <Icon>edit</Icon>Text
                    </MenuItem>
                </AddContextPopupMenu>
                <Gap20 />
                <ContextNote>
                    If your topic requires information or data that the AI is
                    not trained on, you can add
                    additional context to help the AI provide better results.
                </ContextNote>
            </FlexBox>
        </FlexBox>
    );
}
