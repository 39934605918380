import React from "react";
import { GlobalStateController } from "bai-react-global-state";
import type { User } from "firebase/auth";
import _ from "lodash";
import type Stripe from "stripe";

import { workspaces as workspacesApi } from "apis/callables";
import { GetWorkspaceStripeDataResponse } from "apis/workspaces/types";
import { PusherEventType } from "common/constants";
import { IWorkspaceDocumentChangedEvent } from "common/interfaces";
import getLogger, { LogGroup } from "js/core/logger";
import pusher, { ExtendedChannel } from "js/core/services/pusher";
import Spinner from "js/react/components/Spinner";
import { app } from "js/namespaces";

const logger = getLogger(LogGroup.BILLING);

export interface BillingControllerState {
    initialized: boolean;
    initializeError: Error | null;
    workspaceId: string;
    stripeData: GetWorkspaceStripeDataResponse;
}

const initialState: BillingControllerState = {
    initialized: false,
    initializeError: null,
    workspaceId: null,
    stripeData: {
        customer: null,
        subscription: null,
        upcomingInvoice: null,
        invoices: {},
        paymentMethods: {}
    }
};

class BillingController extends GlobalStateController<BillingControllerState> {
    private _asyncActionsPromiseChain: Promise<void> = Promise.resolve();
    private _firebaseUser: User;
    private _pusherChannel: ExtendedChannel;
    private _pusherChannelUnbind: () => void

    public get initialized() {
        return this._state.initialized;
    }

    constructor() {
        super(_.cloneDeep(initialState));
    }

    public async initialize(workspaceId: string, firebaseUser: User) {
        await this._runSequentially(async () => {
            logger.info("[BillingController] initialize()", { workspaceId, uid: firebaseUser.uid });

            await this._reset(false);

            try {
                const stripeData = await workspacesApi.getWorkspaceStripeData({ workspaceId });

                this._pusherChannel = await pusher.subscribe(`private-legacy-workspace-${workspaceId === "personal" ? firebaseUser.uid : workspaceId}`);
                this._pusherChannelUnbind = this._pusherChannel.bindChunked(PusherEventType.DATA_RECORD_UPDATED, this._onPusherEvent);

                this._firebaseUser = firebaseUser;

                await this._updateState({
                    initialized: true,
                    stripeData,
                    workspaceId
                });
            } catch (err) {
                logger.error(err, "[BillingController] initialize() failed");

                await this._updateState({
                    ..._.cloneDeep(initialState),
                    initialized: false,
                    initializeError: err
                });
            }
        });
    }

    public async reset() {
        return this._reset();
    }

    public async cancelSubscription() {
        return this._runSequentially(async () => {
            const subscription = await workspacesApi.cancelSubscription({ workspaceId: this._state.workspaceId });
            await this._updateState(state => ({ ...state, stripeData: { ...state.stripeData, subscription } }));
        });
    }

    public async reactivateSubscription() {
        return this._runSequentially(async () => {
            const subscription = await workspacesApi.reactivateSubscription({ workspaceId: this._state.workspaceId });
            await this._updateState(state => ({ ...state, stripeData: { ...state.stripeData, subscription } }));
        });
    }

    public async switchSubscriptionBillingInterval(billingInterval: "month" | "year") {
        return this._runSequentially(async () => {
            const subscription = await workspacesApi.switchSubscriptionBillingInterval({ workspaceId: this._state.workspaceId, billingInterval });
            await this._updateState(state => ({ ...state, stripeData: { ...state.stripeData, subscription } }));
        });
    }

    public async updatePaymentMethod(paymentMethodId: string) {
        return this._runSequentially(async () => {
            const { subscription, paymentMethods } = await workspacesApi.updatePaymentMethod({ workspaceId: this._state.workspaceId, paymentMethodId });
            await this._updateState(state => ({ ...state, stripeData: { ...state.stripeData, subscription, paymentMethods } }));
        });
    }

    public async payLatestInvoiceOnPastDueSubscription() {
        return this._runSequentially(async () => {
            const { subscription, requiresAction, paymentIntent, invoices, upcomingInvoice } = await workspacesApi.payLatestInvoiceOnPastDueSubscription({ workspaceId: this._state.workspaceId });
            await this._updateState(state => ({ ...state, stripeData: { ...state.stripeData, subscription, requiresAction, paymentIntent, invoices, upcomingInvoice } }));
            return { requiresAction, paymentIntent };
        });
    }

    public async endTrialOnSubscription() {
        return this._runSequentially(async () => {
            const { subscription, upcomingInvoice, invoices } = await workspacesApi.endTrialOnSubscription({ workspaceId: this._state.workspaceId });
            await this._updateState(state => ({ ...state, stripeData: { ...state.stripeData, subscription, upcomingInvoice, invoices } }));
        });
    }

    public async applyPromotionCodeToSubscription(promotionCode: string) {
        return this._runSequentially(async () => {
            const { subscription, upcomingInvoice } = await workspacesApi.applyPromotionCodeToSubscription({ workspaceId: this._state.workspaceId, promotionCode });
            await this._updateState(state => ({ ...state, stripeData: { ...state.stripeData, subscription, upcomingInvoice } }));
        });
    }

    public async updateBillingDetails(update: { address?: Stripe.Address, name?: string, email?: string, taxId?: Pick<Stripe.TaxId, "type" | "value"> }) {
        return this._runSequentially(async () => {
            const customer = await workspacesApi.updateBillingDetails({ workspaceId: this._state.workspaceId, ...update });
            await this._updateState(state => ({ ...state, stripeData: { ...state.stripeData, customer } }));
        });
    }

    public withInitializedState<T extends React.ComponentType<any>>(Component: T, PreloadComponent: React.ComponentType<any> = Spinner, ErrorComponent: React.ComponentType<any> = () => null) {
        function Wrapper(props: React.ComponentProps<T>) {
            const { initialized, initializeError } = props;

            if (initializeError) {
                return <ErrorComponent {...props} />;
            }

            if (!initialized) {
                return <PreloadComponent {...props} />;
            }

            return <Component {...props} />;
        }

        return this.withState(Wrapper);
    }

    public async forceRefreshStripeData() {
        return this._runSequentially(async () => {
            const stripeData = await workspacesApi.getWorkspaceStripeData({ workspaceId: this._state.workspaceId });
            await this._updateState({ stripeData });
        });
    }

    private _runSequentially<T = void>(action: () => Promise<T>) {
        return new Promise<T>((resolve, reject) => {
            this._asyncActionsPromiseChain = this._asyncActionsPromiseChain
                .then(action)
                .then(resolve)
                .catch(reject);
        });
    }

    private _onPusherEvent = (documentChangeEvents: IWorkspaceDocumentChangedEvent[]) => {
        this._runSequentially(async () => {
            for (const event of documentChangeEvents) {
                if (event.documentType === "WorkspacePlan") {
                    try {
                        const stripeData = await workspacesApi.getWorkspaceStripeData({ workspaceId: this._state.workspaceId });
                        this._updateState({ stripeData });
                    } catch (err) {
                        logger.error(err, "[BillingController] _onPusherEvent() failed");
                    }
                }
            }
        });
    }

    private _reset(runSequentially = true) {
        const reset = async () => {
            logger.info("[BillingController] reset()");

            if (this._pusherChannel) {
                this._pusherChannelUnbind();
                if (!this._pusherChannel.isInUse) {
                    pusher.unsubscribe(this._pusherChannel.name);
                }
                this._pusherChannel = null;
                this._pusherChannelUnbind = null;
            }

            await this._updateState(_.cloneDeep(initialState));
        };

        if (runSequentially) {
            return this._runSequentially(reset);
        }

        return reset();
    }
}

const billingController = new BillingController();

// for lazy import and debug
app.billingController = billingController;

export default billingController;
